@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-display: optional;
  src: url("/assets/fonts/Roboto.woff2") format("woff2");
}

body {
  scrollbar-gutter: stable;
  overflow-y: scroll;
}

#__next {
  font-family: Roboto, sans-serif;

  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#__next main {
  margin-bottom: auto;
}

.youtube-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}

.youtube-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
